/* =============================================================================
  Typography

  Sofia Pro 
  Light - 300
  Regular - 400
  Bold - 700

  ========================================================================== */

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {margin:$line-height-computed 0 $line-height-computed / 1.5; padding:0; font-family:$headings-font-family; font-weight:$headings-font-weight; line-height:$headings-line-height; font-style:normal; color:$headings-color; text-rendering:optimizelegibility;
  small, .small {font-weight:normal; line-height:1; color:$headings-small-color;}
}

h1, .h1 {font-size:$font-size-h1;}
h2, .h2 {font-size:$font-size-h2;}
h3, .h3 {font-size:$font-size-h3;}
h4, .h4 {font-size:$font-size-h4;}
h5, .h5 {font-size:$font-size-h5;}
h6, .h6 {font-size:$font-size-h6;}

h1 small, .h1 small {font-size:$font-size-small-h1;}
h2 small, .h2 small {font-size:$font-size-small-h2;}
h3 small, .h3 small {font-size:$font-size-small-h3;}
h4 small, .h4 small {font-size:$font-size-small-h4;}
h5 small, .h5 small {font-size:$font-size-small-h5;}
h6 small, .h6 small {font-size:$font-size-small-h6;}

p {
  margin: 0 0 $line-height-computed / 1.6125;
  font-family: inherit;
  font-size: $font-size-base;
  font-weight: $font-family-weight;
  line-height: $line-height-base;
}

small {font-size:$font-markup-small;}
span {color:$brand-primary;}

p.meta {margin-bottom: 32px;}

/* =============================================================================
  Lists
  ========================================================================== */

// Unordered and Ordered lists
ul,
ol {
  margin-top: 0;
  margin-bottom: ($line-height-computed / 1.6125);
  ul,
  ol {
    margin-bottom: 0;
  }
}

// List options

// [converter] extracted from `.list-unstyled` for libsass compatibility
@mixin list-unstyled {
  padding-left: 0;
  list-style: none;
}
// [converter] extracted as `@mixin list-unstyled` for libsass compatibility
.list-unstyled {
  @include list-unstyled;
}


// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled;
  margin-left: -5px;

  > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
  }
}

// Description Lists
dl {
  margin-top: 0; // Remove browser default
  margin-bottom: $line-height-computed;
}
dt,
dd {
  line-height: $line-height-base;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 0; // Undo browser default
}


article {

  ul {
    position: relative;
    margin: 24px 0;
    margin-left: 24px;

    li {
      position: relative;
      margin-bottom: 16px;
      list-style: none;
      list-style-position: outsite;
      line-height: 1.6125;

      &:before {
        content: "\2022";
        position: absolute;
        left: -15px;
        color: $brand-primary;
        font-weight: 700;
      }
    }
  }

  .list--form {

    li {

      &:before {
        content: "";
      }
    }
  }
}

article {

  table {

    ul {
      margin: 0;
      margin-left: 24px;
    }
  }
}

article {

  .unstyled-list {

    position: relative;
    margin: 24px 0;
    // margin-left:24px;

    li {

      position: relative;
      margin-bottom: 16px;
      list-style: none;
      list-style-position: outsite;
      // line-height:24px;

      &:before {
        content: "";
        // position:absolute;
        // left:-15px;
        // color:$brand-primary;
        // font-weight:700;
      }

    }

  }

}

/* =============================================================================
  Blockquotes
  ========================================================================== */

blockquote {position:relative; margin:0 0 $line-height-computed; padding:($line-height-computed / 1.5) $line-height-computed; font-family:$font-family-sans-serif; font-style:italic;
}

