/* =============================================================================
  common classes
  ========================================================================== */

.break {margin: 30px auto 60px; width: 120px; height:15px; background-size: 120px 15px; background-image: url(../images/break.png);}
.break-white {margin: 30px auto 14px; width: 120px; height:15px; background-size: 120px 15px; background-image: url(../images/break-white@2x.png);}
.break-meta {@extend .break; margin-bottom: 36px;}


.alpha {margin-left:0 !important;}
.omega {margin-right:0 !important;}
.first {margin-top:0 !important;}
.last {margin-bottom:0 !important;}
.left {float:left !important;}
.right {float:right !important;}

.ir { display: block; border: 0; text-indent: -999em; overflow: hidden; background-color: transparent; background-repeat: no-repeat; text-align: left; direction: ltr; }
.ir br { display: none; }
.hidden { display: none !important; visibility: hidden; }
.visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }
.invisible { visibility: hidden; }
.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
.clearfix { zoom: 1; }

.db {display: block;}
.dib {display: inline-block;}
.di {display: inline;}
.df {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
