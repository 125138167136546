#signup {

  padding-top:60px;

  .signup_alerts {padding-bottom:60px;}
  .signup_newsletter {padding-bottom:60px;}

  form {

    legend {

      margin-bottom: 10px;
      padding-bottom: 10px;

    }

    legend, label {

      color: $white;

    }

    button {

      margin-top: 14px;
      margin-bottom: 0;

    }

  }

}

#footer {

  footer {

    position: relative;
    padding-top:75px;
    padding-bottom:75px;
    color: $white;
    text-align: left;

    h3 {

      display: inline-block;
      margin: 0 0 30px;
      padding-bottom: 20px;
      font-size: 24px;
      color: $white;
      border-bottom: 1px solid $white;

      i {

        color: $brand-primary;

      }

    }

    #footer_contact {

      margin-bottom: 40px;

      @include gridle_state ( tb md lg ) {

        margin-bottom: 0;

      }

      .contact_title {

        font-size: 14px;
        font-weight: 700;

      }
      .contact_telephone {

        margin: 0 0 16px;
        font-size: 16px;

        a {

          color: $white;

        }

      }

    }

    #footer_tweets {

      margin-bottom: 40px;

      @include gridle_state ( tb md lg ) {

        margin-bottom: 0;

      }

      .twitter ul li {

        display: block;
        margin-bottom: 8px;
        padding-bottom: 8px;

        .date {

          font-size: $font-size-small;

        }

      }

    }

    #footer_posts {

        li article {

        h4 {

          margin: 0 0 16px;
          font-size: 16px;

          a {color: $white;}

        }

      }

    }

    .satelite {

      position: absolute;
      bottom: 0;
      left: 100px;
      width: 41px;
      height: 54px;
      background-size: 41px 54px; 
      background-image: url(../images/footer-sat.png);

    }

    .flag {

      position: absolute;
      bottom: 0;
      right: 210px;
      width: 46px;
      height: 42px;
      background-size: 46px 42px; 
      background-image: url(../images/footer-flag.png);

    }

    .astronaut {

      position: absolute;
      bottom: 0;
      right: 100px;
      width: 63px;
      height: 54px;
      background-size: 63px 54px; 
      background-image: url(../images/footer-astronaut.png);

    }

    .rocket {

      position: absolute;
      top: 75px;
      left: 250px;
      width: 98px;
      height: 98px;
      background-size: 98px 98px; 
      background-image: url(../images/footer-rocket.png);

      @include gridle_state ( lg ) {

        left: 300px;

      }

    }

  }

}

#base {

  padding-top:60px;
  padding-bottom:60px;
  font-size: $font-size-small;
  color: $white;
  text-align: left;

  #base_content {

    nav {

      ul {

        margin: 14px 0;
        font-size: 0;

        li {

          display: inline-block;
          margin-right: 10px;
          padding-right: 10px;
          border-right: 1px solid $white;

          &:last-child {border-right-width: 0}

          a {

            font-size: 14px;
            line-height: 24px;
            color: $white;

            @include gridle_state ( tb md lg ) {

              line-height: 14px;

            }

          }

        }

      }

    }

    #footer_social {

      display: block;
      float: none;

      @include gridle_state ( tb md lg ) {

        float: right;

      }

      ul {

        margin-bottom: 19px;
        font-size: 0;

        li {

          display: inline-block;
          margin-right: 10px;
          vertical-align: middle;

          &.intro {

            margin-right: 9px;

            @include gridle_state ( md lg ) {

              margin-right: 18px;

            }

          }

          a {

            display: block;
            width: 25px;
            height: 24px;
            background-size: 25px 24px;

            @include gridle_state ( tb md lg ) {

              width: 41px;
              height: 40px;
              background-size: 41px 40px;

            }

          }

          .get-social {

            width: 102px;
            height: 17px;
            background-size: 102px 17px;
            background-image:url(../images/icon-get-social.png);

          }
          .facebook {

            background-image: url(../images/icon-facebook.png);

          }
          .twitter {

            background-image: url(../images/icon-twitter.png);

          }
          .linkedin {

            background-image: url(../images/icon-linkedin.png);

          }

          &:last-child {margin-right: 0;}

        }

      }

    }

    #footer_accred {

      display: block;
      float: none;

      @include gridle_state ( tb md lg ) {

        float: left;

      }

      ul {

        margin-bottom: 19px;
        font-size: 0;

        li {

          display: inline-block;
          margin-right: 10px;
          margin-bottom: 10px;
          vertical-align: middle;

          &.intro {

            margin-right: 9px;

            @include gridle_state ( md lg ) {

              margin-right: 18px;

            }

          }

          &:last-child {margin-right: 0;}

        }

      }

    }

  }

}

.copy {display: block;}
