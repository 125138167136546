#director-profiles {
  padding-bottom: 40px;

  .profile {
    position: relative;
    padding: 10px;

    .profile-content {
      position: relative;
      background-color: $white;
      padding: 20px;

      .headshot {
        position: relative;
        margin: 0 auto 20px;
        width: 130px;
        height: 130px;

        @include gridle_state ( tb md lg ) {
          position: absolute;
          top: 20px;
          left: 20px;
        }

        img {
          width: 130px;
          height: 130px;
          border-radius: 50%;
          -moz-border-radius: 50%;
          -webkit-border-radius: 50%;
        }
      }

      .name {
        padding-top: 0;
        padding-left: 0;
        text-align: center; 
        font-weight: 700;

        @include gridle_state ( tb md lg ) {
          padding-top: 40px;
          padding-left: 150px; 
          text-align: left; 
        }

        .position {
          font-size: $font-size-small;
          font-weight: 300;
        }
      }

      .description {
        padding-top:20px;
        font-size: 16px;
        text-align: center;

        @include gridle_state ( tb md lg ) {
          padding-top:50px;
          text-align: leftf;
        }
      }
    }

    .profile-join {
      position: relative;
      padding: 20px;
      background-color: $brand-primary;
      color: $white;
      text-align: center;

      h4 {
        margin-top:14px;
        font-size: 40px;
        color: $white;
      }

      .large {margin-bottom: 20px; font-size: 24px; font-weight: 200;}
      .small {margin-bottom: 30px; font-size: 14px; line-height: 21px;}

      a {
        margin: 0 20px 20px;
        display: block;

        &:hover {
          color: $brand-primary;
          border-color: $brand-primary;
        }
      }
    }
  }

  .profile-blank-wrap {
    display: none;
    visibility: hidden;

    @include gridle_state ( md lg ) {
      display: block;
      visibility: visible;
    }

    .profile-blank {
      position: relative;

      img {
        position: absolute;
        width: 100% !important;
        height: auto;
        bottom: 0;
      }
    }
  }

  .profile-blank-wrap--last {

    @include gridle_state ( tb ) {
      display: block;
      visibility: visible;
    }
  }
}
