#repeating-logos {

  .logo_content {
    p:last-child {
      margin: 0;
    }
  }

  .logo {
    position: relative;
    padding: 20px 30px;
    // padding-left: 30px;
    text-align: left;

    @include gridle_state ( md lg ) {
      padding-left: 0;
      text-align: center;
    }

    img {
      position: relative;

      @include gridle_state ( md lg ) {
        // position: absolute;
        // top: 10%;
        // left: 32%;
      }
    }
  }
}
