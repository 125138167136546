.modaal-container {
  max-width: 600px;
  background-color: transparent;
}

.modaal-content-container {
  background-color: $white;
  border: 2px solid $brand-primary;
  border-radius: .5rem;

  .logo {
    width: 300px;
    height: 200px;
    margin: 0 auto;
    background-size: 300px 200px;
    background-image:url('../images/logo-onwhite.jpg');
  }
}