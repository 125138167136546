aside {

  h4 {
    margin: 0;
    padding: 20px 0;
    font-size: 20px;
    font-weight: 300;
  }

  .sidebar-images {
    img {
      display: none;

      @include gridle_state ( md lg ) {
        display: block;
        width: 100%;
        height: auto;
        padding-right: 1rem;
        padding-bottom: 1rem;
      }
    }
  }

  ul {
    margin-bottom: 20px;

    li {
      line-height: 48px;

      a {
        display: block;
        padding: 0 20px;
        color: $brand-mid;

        &:hover,
        &:focus {
          color: $brand-primary;
        }
      }

      &.active a {
        background-color: $brand-light;
        color: $brand-primary;
      }
    }
  }
}
