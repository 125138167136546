/* =============================================================================
	Search
	========================================================================== */

#searchform {float:right; margin-top:20px;}
#searchform label {padding-right:5px; font-size: 12px;}
#s {}
#searchsubmit {}
.header-search {width:120px; font-size: 12px;}

/* =============================================================================
	Wordpress Classes
	========================================================================== */

.alignnone {margin: 5px 20px 20px 0;}
.aligncenter, div.aligncenter {display: block; margin: 5px auto 5px auto;}
.alignright {float:right; margin: 5px 0 20px 20px;}
.alignleft {float: left; margin: 5px 20px 20px 0;}
.aligncenter {display: block; margin: 5px auto 5px auto;}
a img.alignright {float: right; margin: 5px 0 20px 20px;}
a img.alignnone {margin: 5px 20px 20px 0;}
a img.alignleft {float: left; margin: 5px 20px 20px 0;}
a img.aligncenter {display: block; margin-left: auto; margin-right: auto}
.wp-caption {padding: 5px 3px 10px; max-width: 96%; background: #fff; border: 1px solid #f0f0f0; text-align: center;}
.wp-caption.alignnone {margin: 5px 20px 20px 0;}
.wp-caption.alignleft {margin: 5px 20px 20px 0;}
.wp-caption.alignright {margin: 5px 0 20px 20px;}
.wp-caption img {margin: 0; padding: 0; width: auto; height: auto; max-width: 98.5%; border: 0 none;}
.wp-caption p.wp-caption-text {margin: 0; padding: 0 4px 5px; font-size: 11px; line-height: 17px;}

.post {}

.entry-content {}
.entry-content a {}
.entry-content a:hover {}

#meta {}
.postmetadata {}

.navgation {}
.next-posts {}
.prev-posts {}

ol.commentlist {list-style: none;}
ol.commentlist li {}
ol.commentlist li.alt {}
ol.commentlist li.bypostauthor {}
ol.commentlist li.byuser {}
ol.commentlist li.comment-author-admin {}
ol.commentlist li.comment {border-bottom: 1px dotted #666; padding: 10px;}
ol.commentlist li.comment div.comment-author {}
ol.commentlist li.comment div.vcard {}
ol.commentlist li.comment div.vcard cite.fn {font-style: normal;}
ol.commentlist li.comment div.vcard cite.fn a.url {}
ol.commentlist li.comment div.vcard img.avatar {float:right; margin: 0 0 10px 10px;}
ol.commentlist li.comment div.vcard img.avatar-32 {}
ol.commentlist li.comment div.vcard img.photo {}
ol.commentlist li.comment div.vcard span.says {}
ol.commentlist li.comment div.commentmetadata {}
ol.commentlist li.comment div.comment-meta {font-size: 10px;}
ol.commentlist li.comment div.comment-meta a {color: #ccc;}
ol.commentlist li.comment p {}
ol.commentlist li.comment ul {}
ol.commentlist li.comment div.reply {font-size: 11px;}
ol.commentlist li.comment div.reply a {font-weight: bold;}
ol.commentlist li.comment ul.children {list-style: none; margin: 10px 0 0;}
ol.commentlist li.comment ul.children li {}
ol.commentlist li.comment ul.children li.alt {}
ol.commentlist li.comment ul.children li.bypostauthor {}
ol.commentlist li.comment ul.children li.byuser {}
ol.commentlist li.comment ul.children li.comment {}
ol.commentlist li.comment ul.children li.comment-author-admin {}
ol.commentlist li.comment ul.children li.depth-2 {border-left: 5px solid #555; margin: 0 0 10px 10px;}
ol.commentlist li.comment ul.children li.depth-3 {border-left: 5px solid #999; margin: 0 0 10px 10px;}
ol.commentlist li.comment ul.children li.depth-4 {border-left: 5px solid #bbb; margin: 0 0 10px 10px;}
ol.commentlist li.comment ul.children li.depth-5 {}
ol.commentlist li.comment ul.children li.odd {}
ol.commentlist li.even {background: #fff;}
ol.commentlist li.odd {background: #f6f6f6;}
ol.commentlist li.parent {border-left: 5px solid #111;}
ol.commentlist li.thread-alt {}
ol.commentlist li.thread-even {}
ol.commentlist li.thread-odd {}

/* =============================================================================
		tinymce
   ========================================================================== */

body#tinymce {
  margin: 12px !important;
  height:auto !important;
}

body.mceContentBody {
  height:auto !important;
  background-color:#FFFFFF !important;
  background-image: none;
  text-align: left;

  h1, h2, h3, h4, h5, h6, p {

    font-family: $font-family-base;
    font-size: $font-size-base;

  }
}
