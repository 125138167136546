.introduction {

  .introduction-content {
    position: relative;

    .headshot {
      position: relative;
      margin: 0 auto 20px;
      width: 200px;
      height: 200px;

      @include gridle_state ( tb md lg ) {
        position: absolute;
        top: 0;
        left: 0;
      }

      img {
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;

        width: 200px;
        height: 200px;
      }
    }

    blockquote {
      padding-left: 0;
      text-align: center;

      @include gridle_state ( tb md lg ) {
        padding-left: 240px;
        text-align: left;
      }

      .introduction_citation {
        font-size: $font-size-small;
      }
    }
  }

  footer {
    text-align: center;

    .btn-long {
      display: none;
      visibility: hidden;

      @include gridle_state ( sm mb tb md lg ) {
        display: inline-block;
        visibility: visible;
      }
    }
    .btn-short {
      display: inline-block;
      visibility: visible;

      @include gridle_state ( sm mb tb md lg ) {
        display: none;
        visibility: hidden;
      }
    }
  }
}
