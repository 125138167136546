header {
  position: relative;
  padding-top: 20px;

  @include gridle_state ( tb md lg ) {
    padding-top: 30px;
  }

  .logo {
    width: 138px;
    height: 82px;
    background-size: 138px 82px;
    background-image:url('../images/logo.png');
  }

  h1 {
    display: inline-block;
    margin: 0;

    .logo {
      @extend .logo;
    }
  }

  nav {
    position: absolute;
    top: 42px;
    right: 0;
    padding-top: 21px;

    @include gridle_state ( tb ) {
      right: 0;
      padding-top: 48px;
    }

    // @include gridle_state ( lg ) {
    //   right: 280px;
    // }
  }

  #header_contact {
    position: absolute;
    top: 0;
    right: 10px;
    padding-top: 1.75rem;
    // width: 480px;
    text-align: right;
    color: $white;

    // @include gridle_state ( lg ) {
    //   padding-top: 32px;
    //   width: 240px;
    // }

    #header_social {
      display: inline;
      float: right;

      ul {
        margin: 0;
        font-size: 0;

        li {
          display: inline-block;
          margin-right: 10px;
          vertical-align: middle;

          &.intro {
            margin-right: 9px;

            // @include gridle_state ( lg ) {
            //   margin-right: 18px;
            // }
          }

          a {
            display: block;
            width: 25px;
            height: 24px;
            background-size: 25px 24px;

            // @include gridle_state ( lg ) {
            //   width: 41px;
            //   height: 40px;
            //   background-size: 41px 40px;
            // }
          }

          .header_telephone {
            // font-size: 1rem;
            // font-weight: 400;

            a {
              display: inline;
              width: auto;
              height: auto;
              // font-weight: 700;
            }
          }

          .get-social {
            width: 102px;
            height: 17px; 
            background-size: 102px 17px;
            background-image:url('../images/icon-get-social.png');
          }

          .facebook {
            background-image: url('../images/icon-facebook.png');
          }

          .twitter {
            background-image: url('../images/icon-twitter.png');
          }

          .linkedin {
            background-image: url('../images/icon-linkedin.png');
          }

          a.tmc {
            width: 50px;
            height: 54px;
            background-size: 50px 54px;
            background-image: url('../images/awards-tmc.jpg');
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .header_telephone {
      display:inline;
      float: right;
      margin-right: 20px;
      font-size: 14px;
      font-weight: 400;

      // @include gridle_state ( lg ) {
      //   margin-right: 0;
      // }

      i {
        padding:0 2px;
        font-size: 15px;
        color: $brand-primary;
      }

      a, span {
        font-size: 16px;
        color: $white;
        font-weight: 700;
      }
    }
  }
}
