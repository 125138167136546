.rocketlist {
  position: relative;
  margin: 24px 0;
  margin-left: 24px;

  li {

    position: relative;
    margin-bottom: 14px;
    padding-left: 7px;
    list-style: none;
    list-style-position: outsite;
    line-height: 24px;

    &:before {
      content: "" !important;
      display: block;
      width: 14px;
      height: 43px;
      position: absolute;
      top: 5px;
      left: -21px;
      background-size: 14px 43px;
      background-image: url('../images/facts-rocket.png');
      background-position: 0 0;
      background-repeat: no-repeat;
    }
  }
}

.list_awards {
  display: block;
  margin: 2rem 0;
  float: none;

  @include gridle_state ( tb md lg ) {
    float: left;
  }

  ul {
    margin-bottom: 19px;
    font-size: 0;

    li {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 10px;
      vertical-align: middle;

      &.intro {

        margin-right: 9px;

        @include gridle_state ( md lg ) {
          margin-right: 18px;
        }
      }

      &:last-child {margin-right: 0;}
    }
  }
}

#footer_social {
  display: block;
  float: none;

  @include gridle_state ( tb md lg ) {
    float: right;
  }

  ul {
    margin-bottom: 19px;
    font-size: 0;

    li {
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle;

      &.intro {
        margin-right: 9px;

        @include gridle_state ( md lg ) {
          margin-right: 18px;
        }
      }

      a {
        display: block;
        width: 25px;
        height: 24px;
        background-size: 25px 24px;

        @include gridle_state ( tb md lg ) {
          width: 41px;
          height: 40px;
          background-size: 41px 40px;
        }
      }

      .get-social {
        width: 102px;
        height: 17px;
        background-size: 102px 17px;
        background-image:url(../images/icon-get-social.png);
      }

      .facebook {
        background-image: url(../images/icon-facebook.png);
      }

      .twitter {
        background-image: url(../images/icon-twitter.png);
      }

      .linkedin {
        background-image: url(../images/icon-linkedin.png);
      }

      &:last-child {margin-right: 0;}
    }
  }
}

#footer_accred {
  display: block;
  float: none;

  @include gridle_state ( tb md lg ) {
    float: left;
  }

  ul {
    margin-bottom: 19px;
    font-size: 0;

    li {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 10px;
      vertical-align: middle;

      img {
        width: auto;
        height: 40px;
      }

      &.intro {
        margin-right: 9px;

        @include gridle_state ( md lg ) {
          margin-right: 18px;
        }
      }

      &:last-child {margin-right: 0;}
    }
  }
}
