#main {

	#blog {

		li {

			position: relative;
			margin-bottom: 20px;

			.blog_img {

				position: relative;
				padding: 0;
				min-height: 240px;

				@include div-bg-image;

			}

			.blog_content {

				position: relative;
				padding: 30px 25px !important;
				background-color: $brand-primary;
				color: $white;

				@include gridle_state ( tb md lg ) {

					padding: 40px 50px !important;

				}

				h2 {

					margin: 0 0 16px;
					padding: 0;
					background: transparent;
					font-size: 20px;
					color: $white;

				}

				p {

					margin-bottom: 20px;

				}

				a {

					display: block;

					&:hover {

						border-color: $white;
						color: $brand-primary;

					}

				}

			}

			&:nth-child(even) {

				.blog_img {

					left: 0%;

					@include gridle_state ( mb tb md lg ) {

						left: 45.8333%;

					}

				}
				.blog_content {

					right: 0%;

					@include gridle_state ( mb tb md lg ) {

						right: 54.1667%;

					}

				}

			}

		}

	}

}