#cta {

	footer {

		text-align: center;

		.btn-long {

			display: none;
			visibility: hidden;

			@include gridle_state ( sm mb tb md lg ) {

				display: inline-block;
				visibility: visible;

			}

		}
		.btn-short {

			display: inline-block;
			visibility: visible;

			@include gridle_state ( sm mb tb md lg ) {

				display: none;
				visibility: hidden;

			}

		}

	}

}
