/* =============================================================================
  Cookies Table
  ========================================================================== */

table.cookies {margin:13px 0;}
table.cookies th, table.cookies td {padding: 10px; border-bottom: 1px solid #CCCCCC; text-align:left;}
table.cookies thead th {border-bottom-color: #CCCCCC;}
table.cookies .cookie-name{width:100px;}
table.cookies .cookie-expire{width:110px;}
table.cookies .cookie-provider{width:110px;}

.cc-cookies {width:100% !important;}