/* =============================================================================
  Toggle
  ========================================================================== */

#menu-button {
  display:inline-block;
  visibility:visible;
  position: relative;
  float:right;
  padding:16px 24px;
  background-color: transparent;
  background-image: none;
  cursor:pointer;

  .icon-bar {
    display: block;
    height: 4px;
    width: 28px;
    background-color: $white;
    border-radius: $border-radius-small;
  }

  .icon-bar + .icon-bar {
    margin-top: 5px;
  }
}

@include gridle_state ( lg ) {

  #menu-button {
    display: none;
    visibility: hidden;
  }
}

/* =============================================================================
  Main Nav
  ========================================================================== */

.sf-menu {
  display:none;

  @include gridle_state ( lg ) {
    display: block;
  }
}

/*** ESSENTIAL SUPERFISH STYLES ***/

.sf-menu,
.sf-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sf-menu li {
  position: relative;
}

.sf-menu ul {
  position: absolute;
  display: none;
  top:100%;
  left:0;
  font-size:0;
  z-index:99;
}

.sf-menu > li {
  float: left;
}

.sf-menu li:hover > ul,
.sf-menu li.sfHover > ul {
  display:block;
}

.sf-menu a {
  display: block;
  position: relative;
}

.sf-menu ul ul {
  top: 0;
  left: 100%;
}

/* =============================================================================
  #menu-primary-navigation
  ========================================================================== */

/* uncomment for centred nav

.sf-menu {
  float: left;
  position: relative;
  left: 50%;
}
*/

.sf-menu {
  min-width: 240px; /* allow long menu items to determine submenu width */
  *width: 240px; /* no auto sub width for IE7, see white-space comment below */
}

.sf-menu li {
  margin-right: 12px;

  @include gridle_state ( lg ) {
    margin-right: 32px;
  }

  white-space: nowrap; /* no need for Supersubs plugin */
  *white-space: normal; /* ...unless you support IE7 (let it wrap) */
  /* left: -50%; uncomment for centred nav */

  &:last-child {
    margin-right: 0;
  }

  a {
    padding: 5px 10px;
    zoom: 1; /* IE7 */
    font-family:$nav-font-family;
    font-size:$nav-font-size;
    color:$nav-font-color;
    font-weight:$nav-font-weight;
    text-decoration:none;
    text-transform:uppercase;

    &:hover, &:focus {
      color:$nav-font-hover-color;
    }

  }
}

.sf-menu li.active a {
  border-bottom: 3px solid $nav-font-hover-color;
  color: $white;
}

.sf-menu li ul {
  padding: 8px 0;
  // background-color: $brand-primary;
  background-color: rgba(37, 170, 225, .9);
  border-radius: 0 8px 8px 8px;
}

.sf-menu li ul a {
  font-size: $nav-dd-font-size;
  color: $nav-dd-font-color;
  border-bottom: 0;

  &:hover,
  &:focus {
    color: $nav-dd-font-hover-color;
  }
}

.sf-menu li.active ul li a {
  border-bottom: 0;
  color: $nav-dd-font-color;
}
