#latestnews, #newsletters {

	li.latestnews-wrap, li.newsletters-wrap {

		position: relative;
		margin-bottom: 20px;

		.latestnews-content, .newsletters-content {

			padding: 20px 30px;
			background-color: $brand-light;
			background-size: 100px 62px;
			background-image: url(../images/logo-small.png);
			background-position: right 10px bottom 20px;
			background-repeat: no-repeat;

			h2 {

				padding-left: 0;
				padding-right: 0;

			}

			p {

				margin-bottom: 50px;

			}

			footer {

				position: absolute;
				bottom: 0;
				padding: 0;
				min-height: 50px;

				a {

					color: $text-color;
					font-weight: 700;

				}

			}

		}

		.newsletters-content {

			background-color: $brand-primary;
			background-image: url(../images/logo-small-white.png);
			color: $white;

			h2 {

				padding-left: 0;
				padding-right: 0;
				background-color: $brand-primary;
				color: $white;

			}

			footer {

				a {

					color: $white;
					font-weight: 700;

				}

			}

		}

	}
		
}