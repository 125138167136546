#reasons-to-believe {

	position: relative;
	padding-bottom: 30px;
	background-size: 127px 738px; 
	background-image: url(../images/road.png);
	background-position: center top;
	background-repeat: repeat-y;
	text-align: center;

	h2 {

		background: $brand-primary !important;
		color: $white;

	}

	#reasons-1, #reasons-2, #reasons-3, #reasons-4, #reasons-5, #reasons-6 {

		position: relative;
		margin-top: 30px;
		padding: 16px 20px 20px;
		width: 100%;
		background: $brand-light;

		@include gridle_state ( tb md lg ) {

			padding: 16px 40px 20px;

		}

		p {

			margin: 0;
			color: $text-color;

		}

	}

}