.masthead {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
  min-height: 500px;
  padding: 0;

  .container-cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    .masthead-content {

      .logo-large {
        display: none;
        visibility: hidden;
        margin: 0 auto;
        width: 280px;
        height: 182px;
        background-size: 280px 182px;
        background-image: url(../images/logo-large.png);

        @include gridle_state ( tb md lg ) {
          display: block;
          visibility: visible;
          width: 416px;
          height: 271px;
          background-size: 416px 271px;
        }

        // @media (max-height: 736px) { v1
        @media (max-height: 900px) {
          display: none;
          visibility: hidden;
        }
      }

      h2,
      p.strapline {
        padding-top: 4rem;
        padding-bottom: 1rem;
        margin-bottom: 0;
        font-size: 20px;
        color: $white;
        line-height: 1.2;
        font-weight: 300;

        @include gridle_state ( tb md lg ) {
          margin-bottom: 1.75rem;
          font-size: 24px;
          line-height: 1.5;
        }
      }

      .btn-long {
        display: none;
        visibility: hidden;

        @include gridle_state ( sm mb tb md lg ) {
          display: inline-block;
          margin: 1rem .5rem 0;
          visibility: visible;
        }
      }

      .btn-short {
        display: inline-block;
        margin: 1rem .5rem 0;
        visibility: visible;

        @include gridle_state ( sm mb tb md lg ) {
          display: none;
          visibility: hidden;
        }
      }
    }

    .fa-angle-down {
      display: none;
      visibility: hidden;
      position: absolute;
      padding-top:2px;
      right: 0;
      left: 0;
      bottom: 40px;
      margin: 0 auto;
      width: 40px;
      height: 40px;
      background-color: $brand-primary;
      font-size: 36px;
      color: $white;
      text-align: center;
      border-radius: 20px 20px 20px 20px;
      -moz-border-radius: 20px 20px 20px 20px;
      -webkit-border-radius: 20px 20px 20px 20px;

      &:hover, &:focus {
        text-decoration: none;
      }

      @include gridle_state ( mb tb md lg ) {
        display: inline;
        visibility: visible;
      }

      @media (max-height: 550px) {
        display: none;
        visibility: hidden;
      }
    }
  }
}

.masthead_home {

  .container {

    #masthead_content {

      #masthead_title {
        position: absolute;
        bottom: 0px;
        right: 20px;
        width: 280px;
        text-align: right;

        @include gridle_state ( sm ) {
          bottom: 40px;
          width: 335px;
        }

        @include gridle_state ( mb ) {
          bottom: 114px;
        }

        @include gridle_state ( tb md lg ) {
          bottom: 120px;
          width: 480px;
        }

        #masthead_comment {

          p:first-child {
            margin-bottom: 14px;
          }

          a.secure_login {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.masthead-content {
  ul.services {
    display: none;
    visibility: hidden;
    margin-top: 3rem;

    @include gridle_state ( tb md lg ) {
      display: block;
      visibility: visible;
    }

    li {
      display: inline-block;
      margin-right: 1.5rem;

      &:last-child {
        margin-right: 0;
      }

      img {

        &:hover {
          filter: brightness(1000%);
          -webkit-filter: brightness(1000%)
        }
      }
    }
  }
}
