.gform_wrapper {
 
	ul {
		@extend .list-unstyled;
		margin: 20px 0 24px;
	}
 
	li {
		@extend .form-group;
		padding:0;
		&:before {content:"";}

	}
 
	form {
		margin-top: 0;
		margin-bottom: 0;
	}
 
	.gfield_required {
		padding-left: 1px;
		color: $state-danger-text;
	}

}
 
.ginput_container {
 
	input,
	select,
	textarea {
		@extend .form-control;
	}
 
	textarea {
		height: auto;
	}

	select {
		width:auto;
	}

	ul.gfield_radio {

		li {
			margin-bottom:8px;
			&:first-child {margin-top:10px;}
		}

	}

	input[type="radio"],
	input[type="checkbox"] {
		position: relative;
		display: inline-block;
		margin: -3px 0 0;
		margin-right:10px;
		width:16px;
		height:16px;
		vertical-align: middle;
		font-weight: normal;
		cursor: pointer;
	}

}

.clear-multi {
	@include clearfix;

	.gfield_date_dropdown_day {display:block; margin-bottom:10px; width:auto;}
	.gfield_date_dropdown_month {display:block; margin-bottom:10px; width:auto;}
	.gfield_date_dropdown_year {display:block; width:auto;}

	@include gridle_state ( mb ) {
		.gfield_date_dropdown_day {display:inline-block; margin-right:10px; margin-bottom:0;}
		.gfield_date_dropdown_month {display:inline-block; margin-right:10px; margin-bottom:0;}
		.gfield_date_dropdown_year {display:inline-block;}
	}
}
 
.gform_button {
@extend .btn;
@extend .btn-brand-primary;
}
 
.gform_wrapper .gfield_error {
 
.gfield_label {
color: $state-danger-text;

	label {
		font-size:$font-size-base;
	}

}
 
input,
select,
textarea {
border-color: $alert-danger-border;
background-color: $alert-danger-bg;
color: $alert-danger-text;
@include form-control-focus( $alert-danger-text );
}
}
 
.validation_error {
@extend .alert;
@extend .alert-danger;
}
 
#gforms_confirmation_message {
@extend .alert;
@extend .alert-success;
}

// hidden labels

.gform_wrapper .hidden_label .gfield_label,
.gform_wrapper label.hidden_sub_label,
.gform_wrapper label.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute!important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.gform_wrapper li.hidden_label input {
  margin-top: 12px;
}

.gform_wrapper .field_sublabel_hidden_label .ginput_complex.ginput_container input[type=text],
.gform_wrapper .field_sublabel_hidden_label .ginput_complex.ginput_container select {
  margin-bottom: 12px;
}

.gform_wrapper .left_label li.hidden_label input,
.gform_wrapper .right_label li.hidden_label input {
  margin-left: 3.7%;
}

.gform_wrapper .hidden_label input.large,
.gform_wrapper .hidden_label select.large,
.gform_wrapper .top_label input.large,
.gform_wrapper .top_label select.large {
  width: 100%;
}
