.technology-one {
  @include div-bg-image;
  background-image: url('../images/hero-testimonials.jpg');
}
.technology-two {
}
.technology-three {
  background-color: #f0faff;
}
.technology-four {
}

.technology-intro {
  text-align: center;

  p {
    margin-bottom: 2rem;
  }

  ul {
    font-size: 0;

    li {
      display: inline;
      margin-right: 14px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.technology-content {
  text-align: left;

  h2 {
    margin-top: 0;
  }

  p {
    margin-bottom: 2rem;
  }

  p.link {
    margin-bottom: .5rem;
  }

  ul {
    font-size: 0;

    li {
      display: inline;
      margin-right: 14px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  header {
    padding: 0;
    text-align: left;

    .break {
      margin-bottom: 30px;
      margin-left: 0;
    }
  }
}

.technology-image {
  img {
    max-width: 100%;
    height: auto;
  }
}

.technology-content--overlay {
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
  // background-color: $white;
  background-color: rgba(255,255,255,.9);
}

.links {
  text-align: center;

  .video-popup {
    margin: 1rem .5rem 0;
  }
}

