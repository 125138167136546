#facts {

	padding-bottom: 40px;

	h4 {

		margin: 0 0 4px;
		font-size: 20px;
		color: $brand-primary;

	}

	p {

		margin-bottom: 40px;

	}

	#left-facts {

		text-align: center;

		@include gridle_state ( tb md lg ) {

			text-align: right;

		}

	}

	#right-facts {

		text-align: center;

		@include gridle_state ( tb md lg ) {

			text-align: left;

		}

	}

	#rocket-facts, #rocket-facts-mobile {

		text-align: center;

		.rocket {

			margin: 0 auto;
			width: 100px;
			height: 304px;
			background-size: 100px 304px; 
			background-image: url(../images/facts-rocket.png);

			@include gridle_state ( md lg ) {

				width: 123px;
				height: 374px;
				background-size: 123px 374px; 

			}

		}

	}

}