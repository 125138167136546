#hero {
  height: 300px;

  @include gridle_state ( tb ) {
    height: 450px;
  }

  @include gridle_state ( md lg ) {
    height: 600px;
  }

  #headline {
    padding:150px 20px 0;
    color: $white;
    text-align: center;

    @include gridle_state ( tb ) {
      padding-top:190px;
    }

    @include gridle_state ( md lg ) {
      padding-top:250px;
    }

    #headline-copy {
      margin-bottom: 30px;
      font-size: 36px;
      line-height: 48px;
      font-weight: 200;

      @include gridle_state ( tb md lg ) {
        margin-bottom: 70px;
        font-size: 48px;
      }
    }

    a {
      display: none;
      visibility: hidden;

      @include gridle_state ( tb md lg ) {
        display: inline;
        visibility: visible;
      }
    }
  }
}
