.page-template-template-no-sidebar {

  .content {
    text-align: center;
  }

  .side-left {
    text-align: right;
  }

  .side-right {
    text-align: left;
  }

  .split-left,
  .split-right {

    img {
      max-width: 100%;
      height: auto;
      padding-bottom: 2rem;
    }
  }

  .image-block {
    @include div-bg-image;
    min-height: 240px;

    @include gridle_state ( md ) {
      min-height: 360px;
    }

    @include gridle_state ( lg ) {
      min-height: 480px;
    }

    .image-overlay {
      padding-top: 30px;
      padding-right: 30px;
      padding-left: 30px;
      background-color: rgba(255,255,255,.9);

      .break {
        margin-bottom: 30px;
      }
    }
  }
}
