#testimonials {

	li {

		position: relative;
		margin-bottom: 20px;

		.testimonial_logo {

			position: relative;
			padding: 20px;
			background-color: $brand-light;

			img {

				width: 100% !important;
				height: auto;

			}

		}
		.testimonial_content {

			position: relative;
			padding: 30px 25px;
			background-color: $brand-primary;
			color: $white;

			@include gridle_state ( tb md lg ) {

				padding: 40px 50px;

			}

			h2 {

				margin: 0 0 16px;
				padding: 0;
				background: transparent;
				font-size: 20px;
				color: $white;

			}

			.citation {

				margin: 0;
				font-weight: 700;

				.citation_position {

					font-size: 14px;
					color: $white;
					font-weight: 200;

				}

			}

		}

		&:nth-child(even) {

			.testimonial_logo {

				left: 0%;

				@include gridle_state ( mb tb md lg ) {

					left: 66.66666%;

				}

			}
			.testimonial_content {

				right: 0%;

				@include gridle_state ( mb tb md lg ) {

					right: 33.33333%;

				}

			}

		}

	}



}