// import settings :
// @import 'settings';

// generate classes :
@include gridle_generate_classes ( );

// manage gridle classes :
.container {
  margin : 0 auto;
}

