#travel-alerts {

	.alert_img {

		padding-top: 30px;
		padding-left: 30px;

		@include gridle_state ( md lg ) {

			padding-left: 0;
			text-align: center;

		}

		.alert_icon {

			width: 100px;
			height: 89px;
			background-size: 100px 89px;
			background-image: url(../images/icon-alert.png);

		}

	}

}