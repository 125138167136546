@font-face {
  font-family: 'FontAwesome';
  src: url('../fonts/fontawesome-webfont.eot?v=4.0.3');
  src: url('../fonts/fontawesome-webfont.eot?#iefix&v=4.0.3') format('embedded-opentype'), url('../fonts/fontawesome-webfont.woff?v=4.0.3') format('woff'), url('../fonts/fontawesome-webfont.ttf?v=4.0.3') format('truetype'), url('../fonts/fontawesome-webfont.svg?v=4.0.3#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
.subscribe-and-connect-connect {
  		position:absolute;
		bottom:0;
		left:0;
		margin:0 0 20px 55px;
		font-size:0;
}
.subscribe-and-connect-connect a {
  text-decoration: none;
  display: block;
  text-indent: -999px;
  position: relative;
  height: 1em;
  width: 1em;
}
.subscribe-and-connect-connect a:before {
  font-family: 'FontAwesome';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  text-indent: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  content: "\f179";
}
.subscribe-and-connect-connect .rss a:before {
  content: '\f09e';
}
.subscribe-and-connect-connect .twitter a:before {
  content: '\f099';
}
.subscribe-and-connect-connect .facebook a:before {
  content: '\f09a';
}
.subscribe-and-connect-connect .pinterest a:before {
  content: '\f0d2';
}
.subscribe-and-connect-connect .youtube a:before {
  content: '\f167';
}
.subscribe-and-connect-connect .instagram a:before {
  content: '\f16d';
}
.subscribe-and-connect-connect .flickr a:before {
  content: '\f16e';
}
.subscribe-and-connect-connect .google_plus a:before {
  content: '\f0d5';
}
.subscribe-and-connect-connect .linkedin a:before {
  content: '\f0e1';
}
.subscribe-and-connect-connect .vimeo a:before {
  content: '\f194';
}
.subscribe-and-connect-connect .tumblr a:before {
  content: '\f173';
}
.subscribe-and-connect-connect .dribbble a:before {
  content: '\f17d';
}
.subscribe-and-connect-connect .appdotnet a:before {
  content: '\f170';
}
.subscribe-and-connect-connect .github a:before {
  content: '\f09b';
}



.subscribe-and-connect-connect .icons {
  list-style: none;
  padding: 0;
  margin: 0;
}
.subscribe-and-connect-connect .icons li {
  display: inline-block;
  padding: 0;
  margin: 0 14px 0 0;

  &:last-child {margin: 0;}
}
.subscribe-and-connect-connect .icons a {
  font-size: 13px;
  padding: 7px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.subscribe-and-connect-connect .icons a:before {
  color: #fff;
  line-height: 2;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
.subscribe-and-connect-connect .icons a:hover {
  opacity: 0.8;
}
.subscribe-and-connect-connect .icons .rss a:before {
  background-color: #ff6600;
}
.subscribe-and-connect-connect .icons .twitter a:before {
  background-color: #33ccff;
}
.subscribe-and-connect-connect .icons .facebook a:before {
  background-color: #3b5998;
}
.subscribe-and-connect-connect .icons .pinterest a:before {
  background-color: #cb2027;
}
.subscribe-and-connect-connect .icons .youtube a:before {
  background-color: #bb0000;
}
.subscribe-and-connect-connect .icons .instagram a:before {
  background-color: #3f729b;
}
.subscribe-and-connect-connect .icons .flickr a:before {
  background-color: #ff0084;
}
.subscribe-and-connect-connect .icons .google_plus a:before {
  background-color: #dd4b39;
}
.subscribe-and-connect-connect .icons .linkedin a:before {
  background-color: #007bb6;
}
.subscribe-and-connect-connect .icons .vimeo a:before {
  background-color: #1ab7ea;
}
.subscribe-and-connect-connect .icons .tumblr a:before {
  background-color: #32506d;
}
.subscribe-and-connect-connect .icons .dribbble a:before {
  background-color: #ea4c89;
}
.subscribe-and-connect-connect .icons .appdotnet a:before {
  background-color: #cccccc;
}
.subscribe-and-connect-connect .icons .github a:before {
  background-color: #333333;
}
