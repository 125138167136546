.single {

  section {

    #main {

      footer {
        padding-top: 20px;
        text-align: center;
      }
    }
  }
}
