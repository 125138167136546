/* Overlay style */
.overlay {
	position: fixed;
	z-index: 2;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(46,48,57,0.98);
}

/* Overlay closing cross */
.overlay .overlay-close {
	width: 40px;
	height: 40px;
	position: absolute;
	right: 20px;
	top: 20px;
	overflow: hidden;
	border: none;
	background-color: transparent;
	background-size: 40px 40px;
	background-image: url(../images/overlay-close.png);
	background-position: center center;
	background-repeat: no-repeat;
	text-indent: 200%;
	color: transparent;
	outline: none;
	z-index: 100;
}

/* Menu style */
.overlay nav {
	text-align: center;
	position: relative;
	top: 50%;
	height: 75%;
	min-height: 24px;
	font-size: 24px;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.overlay ul {
	list-style: none;
	padding: 0;
	margin: 0 auto;
	display: inline-block;
	width: 100%;
	height: 100%;
	position: relative;
}

.overlay ul li {
	display: block;
	height: 20%;
	height: calc(100% / 8);
	min-height: 36px;
}

.overlay ul li a {
	display: block;
	padding: 5px 0;
	color: #fff;
	font-weight: 300;
	-webkit-transition: all 0.4;
	transition: all 0.4s;
}

.overlay ul li.overlay-logo {
	display: block;
	margin-bottom:20px;
	padding: 0;
	min-height: 90px;

	a {

		margin: 0 auto;
		width: 138px;
		height: 82px;
		background-size: 138px 82px;
		background-image:url(../images/logo.png);

		&:hover, &:focus {background-color: transparent;}

	}

}

.overlay ul li a:hover,
.overlay ul li a:focus {
	background-color: $brand-dark;
	color: $brand-primary;
	text-decoration: none;
}

/* Effects */
.overlay-hugeinc {
	opacity: 0;
	visibility: hidden;
	-webkit-transition: opacity 0.5s, visibility 0s 0.5s;
	transition: opacity 0.5s, visibility 0s 0.5s;
}

.overlay-hugeinc.open {
	opacity: 1;
	visibility: visible;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
}

.overlay-hugeinc nav {
	-webkit-perspective: 1200px;
	perspective: 1200px;
}

.overlay-hugeinc nav ul {
	opacity: 0.4;
	-webkit-transform: translateY(-25%) rotateX(35deg);
	transform: translateY(-25%) rotateX(35deg);
	-webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
	transition: transform 0.5s, opacity 0.5s;
}

.overlay-hugeinc.open nav ul {
	opacity: 1;
	-webkit-transform: rotateX(0deg);
	transform: rotateX(0deg);
}

.overlay-hugeinc.close nav ul {
	-webkit-transform: translateY(25%) rotateX(-35deg);
	transform: translateY(25%) rotateX(-35deg);
}
