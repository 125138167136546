button,
input,
optgroup,
select,
textarea {
  font-family: $form-font-family;
}

label {
  font-size: $input-label-font-size;
  color: $input-label-color;
  font-weight: $input-label-font-weight;
}

input[type="text"],
input[type="search"],
input[type="password"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="tel"],
input[type="file"],
textarea,
select {
  @include form-spacing(padding, $form-space);
  display: block;
  width: 100%;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  line-height: normal;
  color: $input-color;
  background: $input-bg;
  border: 1px solid $input-border;
  box-shadow: $input-shadow;
  transition: $input-transition;

  &:focus {
    border-color: $input-border-focus;
    outline: none;
  }
}

input[type="file"] {
  &:hover { cursor: pointer; }
}

input[type="search"] {
  display: inline-block;
  width: auto;
}

select {
  height: ceil($form-line-height * 1.33);
}

select[multiple],
select[size] {
  height: auto;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline-block;
  width: auto;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button {

  // @extend .btn;
  // @extend .btn--btn-primary;

}

// WebKit-style focus
// @link https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/mixins/_tab-focus.scss [props]
input[type="checkbox"],
input[type="radio"],
input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  &:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
}

// field label placement overrides

.gform_wrapper .hidden_label .gfield_label {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.gfield.right_label,
.gfield.left_label {
  padding-top: 10px;
}

.gform_wrapper li.hidden_label input {
  margin-top: 12px;
}

.gform_wrapper .field_sublabel_hidden_label .ginput_complex.ginput_container input[type=text],
.gform_wrapper .field_sublabel_hidden_label .ginput_complex.ginput_container select {
  margin-bottom: 12px;
}

.gform_wrapper label.screen-reader-text, .gform_wrapper label.hidden_sub_label {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
 }


/* =============================================================================
  Base
  ========================================================================== */

form {margin:0;}
fieldset {border:0; margin:0; padding:0;}
label {cursor:pointer;}
legend {border:0; *margin-left:-7px; padding:0;}
button, input, select, textarea {font-size:100%; margin:0; vertical-align:middle; *vertical-align:middle;}
button, input {line-height:normal; *overflow:visible;}
table button, table input {*overflow:auto;}
button, input[type="button"], input[type="reset"], input[type="submit"] {cursor:pointer; -webkit-appearance:button;}
input[type="checkbox"], input[type="radio"] {box-sizing:border-box;}
input[type="search"] {-webkit-appearance:textfield; -moz-box-sizing:content-box; -webkit-box-sizing:content-box; box-sizing:content-box;}
input[type="search"]::-webkit-search-decoration {-webkit-appearance:none;}
button::-moz-focus-inner, input::-moz-focus-inner {border:0; padding:0;}
textarea {overflow:auto; vertical-align:top; resize:vertical;}
input:valid, textarea:valid {}
input:invalid, textarea:invalid {background-color:#f0dddd;}