/* ---------------------------------------------------------- */
/* https://gist.github.com/marcedwards/3446599                */
/* A media query that captures:                               */
/*                                                            */
/* - Retina iOS devices                                       */
/* - Retina Macs running Safari                               */
/* - High DPI Windows PCs running IE 8 and above              */
/* - Low DPI Windows PCs running IE, zoomed in                */
/* - Low DPI Windows PCs and Macs running Firefox, zoomed in  */
/* - Android hdpi devices and above                           */
/* - Android tvdpi devices, including Google Nexus 7          */
/* - Chrome running on high DPI Macs and PCs                  */
/* - Opera running on high DPI Macs, PCs and mobile devices   */
/*                                                            */
/* Please note that that this code assumes you'll swap a      */
/* 2× version of your images. If you'd like to supply         */
/* finer increments, other thresholds might be appropriate.   */
/*                                                            */
/* A test for CSS pixel densites can be found here:           */
/* http://bjango.com/articles/min-device-pixel-ratio/         */
/*                                                            */
/*                                  @marcedwards from @bjango */
/*                                                            */
/* ---------------------------------------------------------- */

@media	only screen and (-webkit-min-device-pixel-ratio: 1.3),
	only screen and (-o-min-device-pixel-ratio: 13/10),
	only screen and (min-resolution: 120dpi)
	{

		section {
			header{
				.break {background-image: url(../images/break@2x.png);}
			}
		}

		.overlay .overlay-close {background-image: url(../images/overlay-close@2x.png);}
		.overlay ul li.overlay-logo a {background-image: url(../images/logo@2x.png);}

		.masthead .container-cell .container .logo-large {background-image: url(../images/logo-large@2x.png);}

		header {

			.logo {background-image:url(../images/logo@2x.png);}

			#header_contact {
				#header_social {
					ul {
						li {
							.get-social {background-image: url(../images/icon-get-social@2x.png);}
							.facebook {background-image: url(../images/icon-facebook@2x.png);}
							.twitter {background-image: url(../images/icon-twitter@2x.png);}
							.linkedin {background-image: url(../images/icon-linkedin@2x.png);}
						}

					}

				}

			}

		}

		#facts #rocket-facts .rocket {background-image: url(../images/facts-rocket@2x.png);}

		#latestnews, #newsletters {

			li.latestnews-wrap, li.newsletters-wrap {

				.latestnews-content, .newsletters-content {

					background-image: url(../images/logo-small@2x.png);

				}

				.newsletters-content {

					background-image: url(../images/logo-small-white@2x.png);

				}

			}
				
		}

		#footer {

			footer {

				.satelite {background-image: url(../images/footer-sat@2x.png);}
				.flag {background-image: url(../images/footer-flag@2x.png);}
				.astronaut {background-image: url(../images/footer-astronaut@2x.png);}
				.rocket {background-image: url(../images/footer-rocket@2x.png);}

			}

		}

		#base {

			#base_content{

				#footer_social {
					ul {
						li {
							.get-social {background-image: url(../images/icon-get-social@2x.png);}
							.facebook {background-image: url(../images/icon-facebook@2x.png);}
							.twitter {background-image: url(../images/icon-twitter@2x.png);}
							.linkedin {background-image: url(../images/icon-linkedin@2x.png);}
						}

					}

				}

			}

		}

	}
