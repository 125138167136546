/* =============================================================================
	variables
	========================================================================== */

/* =============================================================================
	Colors
	========================================================================== */

$black:										#000 !default;
$grey-darker:								lighten($black, 13.5%) !default; // #222
$grey-dark:									lighten($black, 20%) !default;   // #333
$grey:										lighten($black, 33.5%) !default; // #555
$grey-light:								lighten($black, 46.7%) !default; // #777
$grey-lighter:								lighten($black, 93.5%) !default; // #eee
$white:										#fff !default;

$brand-primary:								#25aae1 !default;
$brand-dark:								#2e3039 !default;
$brand-mid:									#494c5a !default;
$brand-light:								#f0faff !default;

$brand-success:								#5cb85c !default;
$brand-info:								#5bc0de !default;
$brand-warning:								#f0ad4e !default;
$brand-danger:								#d9534f !default;

/* =============================================================================
	Scaffolding
	========================================================================== */

$body-bg:									#fff !default;
$text-color:								$brand-mid !default;
$text-muted:								$grey-light !default;
$hr-border:									$grey-lighter !default;

/* =============================================================================
	Typography
	========================================================================== */

$font-family-sans-serif:					"sofia-pro", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif:							Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:						Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:							$font-family-sans-serif !default;
$font-family-weight:						200 !default;

$font-size-base:							18px !default; // was 16px. Other values are unchanged (still based on 16px)
$font-size-large:							ceil(($font-size-base * 1.1)) !default; // ~18px
$font-size-small:							ceil(($font-size-base * 0.85)) !default; // ~14px
$font-markup-small:							ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1:								floor(($font-size-base * 2.25)) !default; // ~36px
$font-size-h2:								floor(($font-size-base * 2.5)) !default; // ~40px
$font-size-h3:								floor(($font-size-base * 1.5)) !default; // ~24px
$font-size-h4:								ceil(($font-size-base * 1.1)) !default; // ~18px
$font-size-h5:								$font-size-base !default;
$font-size-h6:								ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-small-h1:						floor(($font-size-base * 1.9)) !default; // ~30px
$font-size-small-h2:						ceil(($font-size-base * 15)) !default; // ~24px
$font-size-small-h3:						ceil(($font-size-base * 1.1)) !default; // ~18px
$font-size-small-h4:						$font-size-base !default;
$font-size-small-h5:						ceil(($font-size-base * 0.85)) !default; // ~12px
$font-size-small-h6:						ceil(($font-size-base * 0.85)) !default; // ~12px

$line-height-base:							1.6125 !default; // 26/16
$line-height-computed:						floor(($font-size-base * $line-height-base)) !default; // ~20px
$line-height-large:							1.33 !default;
$line-height-small:							1.5 !default;

$headings-font-family:						inherit !default;
$headings-font-weight:						200 !default;
$headings-line-height:						1.1 !default;
$headings-color:							$brand-mid !default;
$headings-small-color:						$grey-light !default;

$link-color:								$brand-primary !default;
$link-hover-color:							darken($link-color, 15%) !default;
$link-hover-decoration:						underline !default;

$blockquote-font-size:						($font-size-base * 1.25) !default;

$code-color:								#c7254e !default;
$code-bg:									#f9f2f4 !default;
$ins-color:									$grey-dark !default;
$ins-bg:									#ff9 !default;
$mark-color:								$grey-dark !default;
$mark-bg:									#ff0 !default;
$pre-bg:									#f5f5f5 !default;
$pre-color:									$grey-dark !default;
$pre-border-color:							#ccc !default;
$pre-scrollable-max-height:					340px !default;

/* =============================================================================
	Navigation
	========================================================================== */

$nav-font-family:							inherit !default;
$nav-font-size:								14px !default;
$nav-font-color:							$white !default;
$nav-font-hover-color:						$brand-primary !default;
$nav-font-weight:							700 !default;
$nav-bg-color:								transparent !default;
$nav-bg-hover-color:						transparent !default;

$nav-dd-font-size:							13px !default; // ~13px
$nav-dd-font-color:							$white !default;
$nav-dd-font-hover-color:					$white !default;
$nav-dd-bg-color:							transparent !default;
$nav-dd-bg-hover-color:						transparent !default;

/* =============================================================================
	Padding and Border Radius
	Values based on 14px text and 1.428 line-height (~20px to start)
	========================================================================== */

$padding-base-vertical:						6px !default;
$padding-base-horizontal:					12px !default;

$padding-large-vertical:					10px !default;
$padding-large-horizontal:					16px !default;

$padding-small-vertical:					5px !default;
$padding-small-horizontal:					10px !default;

$padding-xs-vertical:						1px !default;
$padding-xs-horizontal:						5px !default;

$border-radius-base:						4px !default;
$border-radius-large:						6px !default;
$border-radius-small:						3px !default;
$border-radius-none:						0 !default;

/* =============================================================================
	Tables
	========================================================================== */

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding:            8px !default;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding:  5px !default;

//** Default background color used for all tables.
$table-bg:                      transparent !default;
//** Background color used for `.table-striped`.
$table-bg-accent:               #f9f9f9 !default;
//** Background color used for `.table-hover`.
$table-bg-hover:                #f5f5f5 !default;
$table-bg-active:               $table-bg-hover !default;

//** Border color for table and cell borders.
$table-border-color:            #ddd !default;

/* =============================================================================
	Buttons
	========================================================================== */

$btn-font-weight:							300 !default;

$btn-brand-color:							$brand-primary !default;
$btn-brand-bg:								transparent !default;
$btn-brand-border:							$brand-primary !default;

$btn-white-color:							$white !default;
$btn-white-bg:								transparent !default;
$btn-white-border:							$white !default;

$btn-default-color:							$brand-dark !default;
$btn-default-bg:							transparent !default;
$btn-default-border:						$brand-dark !default;

$btn-primary-color:							#fff !default;
$btn-primary-bg:							$brand-primary !default;
$btn-primary-border:						darken($btn-primary-bg, 5%) !default;

$btn-success-color:							#fff !default;
$btn-success-bg:							$brand-success !default;
$btn-success-border:						darken($btn-success-bg, 5%) !default;

$btn-info-color:							#fff !default;
$btn-info-bg:								$brand-info !default;
$btn-info-border:							darken($btn-info-bg, 5%) !default;

$btn-warning-color:							#fff !default;
$btn-warning-bg:							$brand-warning !default;
$btn-warning-border:						darken($btn-warning-bg, 5%) !default;

$btn-danger-color:							#fff !default;
$btn-danger-bg: 							$brand-danger !default;
$btn-danger-border:							darken($btn-danger-bg, 5%) !default;

$btn-link-disabled-color:					$grey-light !default;

$btn-padding-horizontal:					30px !default;
$btn-padding-vertical:						20px !default;
$btn-font-size:								18px !default;
$btn-border-radius:							0 !default;

/* =============================================================================
	Forms
	========================================================================== */

//** `<input>` background color
$input-bg:									#fff !default;
//** `<input disabled>` background color
$input-bg-disabled:							$grey-lighter !default;

//** Text color for `<input>`s
$input-color:								$grey !default;
//** `<input>` border color
$input-border:								#ccc !default;

// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
$input-border-radius:						$border-radius-base !default;
//** Large `.form-control` border radius
$input-border-radius-large:					$border-radius-large !default;
//** Small `.form-control` border radius
$input-border-radius-small:					$border-radius-small !default;

//** Border color for inputs on focus
$input-border-focus:						#66afe9 !default;

//** Placeholder text color
$input-color-placeholder:					#999 !default;

//** Default `.form-control` height
$input-height-base:							($line-height-computed + ($padding-base-vertical * 2) + 2) !default;
//** Large `.form-control` height
$input-height-large:						(ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2) !default;
//** Small `.form-control` height
$input-height-small:						(floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2) !default;

$legend-color:								$grey-dark !default;
$legend-border-color:						#e5e5e5 !default;

//** Background color for textual input addons
$input-group-addon-bg:						$grey-lighter !default;
//** Border color for textual input addons
$input-group-addon-border-color:			$input-border !default;

//** Disabled cursor for form controls and buttons.
$cursor-disabled:							not-allowed !default;

/* =============================================================================
	Form states and alerts
	========================================================================== */

$state-success-text:						#3c763d !default;
$state-success-bg:							#dff0d8 !default;
$state-success-border:						darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text:							#31708f !default;
$state-info-bg:								#d9edf7 !default;
$state-info-border:							darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-warning-text:						#8a6d3b !default;
$state-warning-bg:							#fcf8e3 !default;
$state-warning-border:						darken(adjust-hue($state-warning-bg, -10), 5%) !default;

$state-danger-text:							#a94442 !default;
$state-danger-bg:							#f2dede !default;
$state-danger-border:						darken(adjust-hue($state-danger-bg, -10), 5%) !default;

$alert-padding:								15px !default;
$alert-border-radius:						$border-radius-base !default;
$alert-link-font-weight:					bold !default;

$alert-success-bg:							$state-success-bg !default;
$alert-success-text:						$state-success-text !default;
$alert-success-border:						$state-success-border !default;

$alert-info-bg:								$state-info-bg !default;
$alert-info-text:							$state-info-text !default;
$alert-info-border:							$state-info-border !default;

$alert-warning-bg:							$state-warning-bg !default;
$alert-warning-text:						$state-warning-text !default;
$alert-warning-border:						$state-warning-border !default;

$alert-danger-bg:							$state-danger-bg !default;
$alert-danger-text:							$state-danger-text !default;
$alert-danger-border:						$state-danger-border !default;
