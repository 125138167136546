#travel-sectors {

  .sector_img {
    position: relative;
    padding-top: 40px;
    padding-left: 30px;
    text-align: left;

    @include gridle_state ( md lg ) {
      padding-left: 0;
      text-align: center;
    }

    img {
      position: relative;

      @include gridle_state ( md lg ) {
        position: absolute;
        top: 32%;
        left: 32%;
      }
    }
  }
}
