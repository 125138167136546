// import settings :
// @import 'settings';

header {
  @include gridle ( 24 );
  @include gridle_parent ( );

  nav#nav {
    @include gridle ( 24 );
    @include gridle_no_gutter( left right, mb tb md lg );
  }
}

.masthead-content {

  @include gridle ( 20 );
  @include gridle_prefix ( 2 );
  @include gridle_suffix ( 2 );

}

.page-header,
#cta {
  @include gridle ( 22 );
  @include gridle_prefix ( 1 );
  @include gridle_suffix ( 1 );
}

.introduction,
.technology-intro {
  @include gridle ( 22 );
  @include gridle_prefix ( 1 );
  @include gridle_suffix ( 1 );

  @include gridle ( 18, md lg );
  @include gridle_prefix ( 3, md lg );
  @include gridle_suffix ( 3, md lg );
}

.video-wrap {
  @include gridle ( 24 );
}


// nosidebar-repeater left/right content

// images first on mobile

.side-left.split-left {
  @include gridle_pull ( 0 );
  @include gridle_pull ( 12, tb md lg );
}

.side-left.split-right {
  @include gridle_push ( 0 );
  @include gridle_push ( 12, tb md lg );
}

// split left/right

.split-left {
  @include gridle ( 22 );
  @include gridle_prefix ( 1 );
  @include gridle_suffix ( 1 );

  @include gridle ( 12, tb md lg );
  @include gridle_prefix ( 0, tb md lg );
  @include gridle_suffix ( 0, tb md lg );
}

.split-right {
  @include gridle ( 22 );
  @include gridle_prefix ( 1 );
  @include gridle_suffix ( 1 );

  @include gridle ( 12, tb md lg );
  @include gridle_prefix ( 0, tb md lg );
  @include gridle_suffix ( 0, tb md lg);
}

// image overlay position

.push-left {
  @include gridle ( 22 );
  @include gridle_prefix ( 1 );
  @include gridle_suffix ( 1 );

  @include gridle ( 12, tb md lg );
  @include gridle_prefix ( 0, tb md lg );
  @include gridle_suffix ( 12, tb md lg );
}

.push-right {
  @include gridle ( 22 );
  @include gridle_prefix ( 1 );
  @include gridle_suffix ( 1 );

  @include gridle ( 12, tb md lg );
  @include gridle_prefix ( 12, tb md lg );
  @include gridle_suffix ( 0, tb md lg);
}

.technology-content-left {
  @include gridle ( 22 );
  @include gridle_prefix ( 1 );
  @include gridle_suffix ( 1 );

  @include gridle ( 12, tb md lg );
  @include gridle_prefix ( 0, tb md lg );
  @include gridle_suffix ( 0, tb md lg );
}

.technology-content-right {
  @include gridle ( 22 );
  @include gridle_prefix ( 1 );
  @include gridle_suffix ( 1 );

  @include gridle ( 12, tb md lg );
  @include gridle_prefix ( 0, tb md lg );
  @include gridle_suffix ( 0, tb md lg);
}

.technology-three {

  .technology-content-left {
    @include gridle_push ( 0 );
    @include gridle_push ( 12, tb md lg );
  }

  .technology-content-right {
    @include gridle_pull ( 0 );
    @include gridle_pull ( 12, tb md lg );
  }
}

#facts {
  @include gridle ( 24 );
  @include gridle_parent ( );

  #left-facts {
    @include gridle ( 22 );
    @include gridle_prefix ( 1 );
    @include gridle_suffix ( 1 );
    @include gridle ( 10, tb md lg );
    @include gridle_prefix ( 0, tb md lg );
    @include gridle_suffix ( 0, tb md lg );
  }

  #rocket-facts {
    @include gridle ( 22 );
    @include gridle_prefix ( 1 );
    @include gridle_suffix ( 1 );
    @include gridle ( 4, tb md lg );
    @include gridle_prefix ( 0, tb md lg );
    @include gridle_suffix ( 0, tb md lg );
  }

  #rocket-facts-mobile {
    @include gridle ( 22 );
    @include gridle_prefix ( 1 );
    @include gridle_suffix ( 1 );
  }

  #right-facts {
    @include gridle ( 22 );
    @include gridle_prefix ( 1 );
    @include gridle_suffix ( 1 );
    @include gridle ( 10, tb md lg );
    @include gridle_prefix ( 0, tb md lg );
    @include gridle_suffix ( 0, tb md lg );
  }
}

#directors {
  @include gridle ( 24 );
  @include gridle_parent ( );

  .profile {
    @include gridle ( 24 );
    @include gridle ( 12, tb );
    @include gridle ( 8, md lg );
  }

  .profile-join-wrap {
    @include gridle ( 24 );
  }
}

#main {

  @include gridle ( 22 );
  @include gridle_prefix ( 1 );
  @include gridle_suffix ( 1 );

  @include gridle ( 18, md lg );
  @include gridle_prefix ( 0, md lg );
  @include gridle_suffix ( 0, md lg );

  @include gridle_parent ( );

  .sector_content,
  .partner_content {
    @include gridle ( 24 );
    @include gridle ( 12, md lg );
  }

  .sector_img,
  .logo {
    @include gridle ( 24 );
    @include gridle ( 12, md lg );
  }

  .testimonial_logo {
    @include gridle ( 24 );
    @include gridle ( 8, mb tb md lg );
  }

  .testimonial_content {
    @include gridle ( 24 );
    @include gridle ( 16, mb tb md lg );
  }

  .alert_content {
    @include gridle ( 24 );
    @include gridle ( 20, md lg );
  }

  .alert_img {
    @include gridle ( 24 );
    @include gridle ( 4, md lg );
  }

  #latestnews li,
  #newsletters li {
    @include gridle ( 24 );
    @include gridle ( 12, tb md );
    @include gridle ( 8, lg );
  }

  ul#blog {

    li {
      width: 100%;

      .blog_img {
        @include gridle ( 24 );
        @include gridle ( 13, mb tb md lg );
      }

      .blog_content {
        @include gridle ( 24 );
        @include gridle ( 11, mb tb md lg );
      }
    }
  }
}

#reasons-to-believe {

  #left-reasons{
    @include gridle ( 22 );
    @include gridle_prefix ( 1 );
    @include gridle_suffix ( 1 );
    @include gridle ( 10, tb md lg );
    @include gridle_prefix ( 0, tb md lg );
    @include gridle_suffix ( 0, tb md lg );

  }
  #road-reasons{
    @include gridle ( 22 );
    @include gridle_prefix ( 1 );
    @include gridle_suffix ( 1 );
    @include gridle ( 4, tb md lg );
    @include gridle_prefix ( 0, tb md lg );
    @include gridle_suffix ( 0, tb md lg );
  }

  #right-reasons{
    @include gridle ( 22 );
    @include gridle_prefix ( 1 );
    @include gridle_suffix ( 1 );
    @include gridle ( 10, tb md lg );
    @include gridle_prefix ( 0, tb md lg );
    @include gridle_suffix ( 0, tb md lg );
  }
}

aside {
  @include gridle ( 22 );
  @include gridle_prefix ( 1 );
  @include gridle_suffix ( 1 );

  @include gridle ( 6, md lg );
  @include gridle_prefix ( 0, md lg );
  @include gridle_suffix ( 0, md lg );
}

aside.sidebar-mobile {
  @include gridle ( 22 );
  @include gridle_prefix ( 1 );
  @include gridle_suffix ( 1 );

  @include gridle ( 6, md lg );
  @include gridle_prefix ( 0, md lg );
  @include gridle_suffix ( 0, md lg );
}

#signup {

  .signup_alerts, .signup_newsletter {
    @include gridle ( 22 );
    @include gridle_prefix ( 1 );
    @include gridle_suffix ( 1 );

    @include gridle ( 12, tb md lg );
    @include gridle_prefix ( 6, tb md lg );
    @include gridle_suffix ( 6, tb md lg );
  }
}

footer {
  @include gridle ( 24 );
  @include gridle_parent ( );

  #footer_contact {
    @include gridle ( 22 );
    @include gridle_prefix ( 1 );
    @include gridle_suffix ( 1 );

    @include gridle ( 8, tb );
    @include gridle_prefix ( 0, tb );
    @include gridle_suffix ( 0, tb );

    @include gridle ( 8, md lg );
    @include gridle_prefix ( 0, md lg );
    @include gridle_suffix ( 2, md lg );
  }
  #footer_tweets {
    @include gridle ( 22 );
    @include gridle_prefix ( 1 );
    @include gridle_suffix ( 1 );

    @include gridle ( 8, tb md lg );
    @include gridle_prefix ( 0, tb md lg );
    @include gridle_suffix ( 0, tb md lg );
  }

  #footer_posts {
    @include gridle ( 22 );
    @include gridle_prefix ( 1 );
    @include gridle_suffix ( 1 );

    @include gridle ( 7, tb );
    @include gridle_prefix ( 1, tb );
    @include gridle_suffix ( 0, tb );

    @include gridle ( 5, md lg );
    @include gridle_prefix ( 1, md lg );
    @include gridle_suffix ( 0, md lg );
  }
}

#base_content {
  @include gridle ( 24 );
}
