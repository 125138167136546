.image-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.image-row__img {
  width: 33.333%;
  padding-bottom: 22.222%;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
