section {
  padding-top: 50px;
  padding-bottom: 50px;

  header {
    padding-top: 0;
    text-align: center;
  }

  #main {
    padding-bottom: 50px;

    @include gridle_state ( tb md lg ) {
      padding-bottom: 0;
    }

    .featured-image {
      margin-bottom: 50px;

      img {
        width: 100% !important;
        height: auto;
      }
    }

    h2 {
      margin: 0;
      padding: 20px 30px;
      background-color: #f0faff;
      font-size: 20px;
    }

    .sector_content,
    .partner_content,
    .alert_content {
      padding: 20px 30px;
    }

    .gform_wrapper {
      padding-left: 0;
      padding-right: 0;

      @include gridle_state ( tb md lg ) {
        padding-left: 15%;
        padding-right: 15%;
      }
    }
  }
}
