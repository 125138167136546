#cookie-notice {
  font-size: 1rem;
  line-height: 1.4;
  color: rgb(0, 0, 0) !important;
  background-color: rgb(255, 255, 255) !important;
  box-shadow: 0 0 30px #6549cb;

  span {
    display: block;
    margin-bottom: 1rem;
    color: rgb(0, 0, 0) !important;
  }

  .button {
    display: inline-block;
    padding: 20px 60px;
    margin-top: 1rem;
    height: auto;
    line-height: 1;
    color: #fff;
    font-weight: 700;
    background: #6549cb;

    &:first-of-type {
      margin-top: 0;
    }
  }
}

.cookie-notice-container {
  padding: 25px 30px;
  text-align: center;
  box-shadow: 0 0 0 5px #6549cb;
}
