/* =============================================================================
	Reset the box-sizing
	========================================================================== */

* {@include box-sizing(border-box);}
*:before, *:after {@include box-sizing(border-box);}

/* =============================================================================
	Base
	========================================================================== */

html {width: 100%; height: 100%; font-size:100%; overflow-y:scroll;}
body {width: 100%; height: 100%; margin:0; background:$body-bg; font-size:$font-size-base; line-height:$line-height-base;}
body, button, input, select, textarea {font-family:$font-family-base; color:$text-color; font-weight:$font-family-weight;}

::-moz-selection {background:$brand-primary; color:$white; text-shadow:none;}
::selection {background:$brand-primary; color:$white; text-shadow:none;}

/* =============================================================================
	Links
	========================================================================== */

a {color:$link-color; text-decoration:none;
	&:hover, &:focus {color:$link-hover-color; text-decoration:$link-hover-decoration;}
	&:focus, &:active {outline:0;}
}

/* =============================================================================
	Typography
	========================================================================== */

abbr[title] {border-bottom:1px dotted;}
b, strong {font-weight:bold;}
blockquote {margin:1em 40px;}
dfn {font-style: italic;}
hr {display:block; height:1px; border:0; border-top:1px solid $hr-border; margin:30px 0; padding:0; }
ins {background:$ins-bg; color:$ins-color; text-decoration:none;}
mark {background:$mark-bg; color:$ins-color; font-style:italic; font-weight:bold;}
pre, code, kbd, samp {font-family:$font-family-monospace; _font-family:$font-family-monospace; font-size:$font-size-base;}
pre {white-space:pre; white-space:pre-wrap; word-wrap:break-word;}
q {quotes:none;
	&:before, &:after {content:""; content:none;}
}
sub, sup {font-size:75%; line-height:0; position:relative; vertical-align:baseline;}
sup {top:-0.5em;}
sub {bottom:-0.25em;}

/* =============================================================================
	Lists
	========================================================================== */

ul, ol, dd, nav ul, nav ol {margin:0; padding:0; list-style:none; list-style-image:none;}

/* =============================================================================
	Embedded content
	========================================================================== */

img {border:0; -ms-interpolation-mode:bicubic; vertical-align:middle;}
svg:not(:root) {overflow:hidden;}
audio, canvas, iframe, img, svg, video {vertical-align: middle;}

/* =============================================================================
	Figures
	========================================================================== */

figure {margin:0;}

/*
 * Responsive images by default
 */

img {
  height: auto;
  max-width: 100%;
}
