/* =============================================================================
  Wrapper Styles
  ========================================================================== */

.wrap {
  @include wrapper;
}

#header {
  position: absolute;
  z-index: 1;
}

.masthead {
  width: 100%;
  height: 100%;
}

.masthead_home {
  background: #070811 url(../images/masthead-home.jpg) no-repeat center bottom;
  background-size: cover;
}

#hero {
  @include div-bg-image;
}

#facts-wrapper, #directors, #cta-wrapper {
  position: relative;
  background-color: #f0faff;
  overflow: hidden;

  #left-clouds {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 485px;
    height: 292px;
    background-size: 485px 292px;
    background-image: url(../images/facts-left-cloud.png);
    z-index: 1;
  }

  #right-clouds {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 485px;
    height: 292px;
    background-size: 485px 292px;
    background-image: url(../images/facts-right-cloud.png);
    z-index: 1;
  }

  #clouds-zindex {
    position: relative;
    z-index: 2;
  }
}

#cta-wrapper {

  #left-clouds {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 217px;
    height: 128px;
    background-size: 217px 128px;
    background-image: url(../images/cta-left-cloud.png);
    z-index: 1;

    @include gridle_state ( tb ) {
      width: 280px;
      height: 165px;
      background-size: 280px 165px;
    }

    @include gridle_state ( md lg ) {
      width: 433px;
      height: 256px;
      background-size: 433px 256px;
    }
  }

  #right-clouds {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 185px;
    height: 134px;
    background-size: 185px 134px;
    background-image: url(../images/cta-right-cloud.png);
    z-index: 1;

    @include gridle_state ( tb ) {
      width: 250px;
      height: 181px;
      background-size: 250px 181px;
    }

    @include gridle_state ( md lg ) {
      width: 391px;
      height: 283px;
      background-size: 391px 283px;
    }
  }
}

#team-photo {

  @include div-bg-image;
  height: 200px;
  background-image: url(../images/meet-the-team3.jpg);

  @include gridle_state ( tb ) {
    height: 300px;
  }

  @include gridle_state ( md ) {
    height: 450px;
  }

  @include gridle_state ( lg ) {
    height: 600px;
  }
}

#signup {
  background-color: $brand-mid;
}

#footer {
  background-color: $brand-dark;
}

#base {
  background-color: $brand-mid;
}
